
import { defineComponent, ref, onMounted, computed, onBeforeMount } from "vue";
import CustomerService from "@/core/services/car/CustomerService";
import { useStore } from "vuex";
import { hideModal } from "@/core/helpers/dom";

interface AddImportParams {
  file: string;
}
export default defineComponent({
  name: "customer-import-modal",
  components: {},
    setup() {
    // let customers_obj = ref([]);
    const store = useStore();
    const importCustomerModalRef = ref<null | HTMLElement>(null);
    const importCustomer = ref();
    let ShowLoading = ref<boolean>(true);
    const addImportModalRef = ref<null | HTMLElement>(null);
    let addImportParams = ref<AddImportParams>({
      file: "",
    });
    const rules = ref({
      file_data: {
        show: false,
        message: "File is required",
      },
    });

    let data: any;
    let file = ref<any>("");
    const handleFileUpload = (event) => {
      data = new FormData();
      let file = event.target.files[0];
      addImportParams.value.file = file;
      data.append("file", file);
      // console.log(data);
    };
    const importFile = () => {
      // console.log(data);
      if (addImportParams.value.file == "") {
        rules.value.file_data.show = true;
      }
      else
      {
        ShowLoading.value = true;
        Promise.all([
          CustomerService.importCustomerList(data)
        ]).then((data) => {
          importCustomer.value.reset();
          importCustomerModalRef.value = null
          ShowLoading.value = false;
          resetFormValue();
          hideModal(addImportModalRef.value);
        });
      
      }
     
    };
    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });
    const resetFormValue = () => {
      addImportParams.value.file = "";
      rules.value.file_data.show = false;
      importCustomer.value.reset()
    };
    /**
     * return data
     *
     */

    const closeModal = (e) => {
      if(e.which === 27) {
        resetFormValue()
      }
    }

    onBeforeMount(() => {
      window.addEventListener('keyup', closeModal);
    })
    return {
      closeModal,
      importCustomer,
      importCustomerModalRef,
      handleFileUpload,
      importFile,
      rules,
      addImportModalRef,
      disableSaveButton,
      resetFormValue,
      addImportParams,
      ShowLoading,
      // uploadFile,
      // formData
    };
  },
});
